import React, { useRef } from "react";
import { useDispatch, useSelector,RootStateOrAny } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Translate from "translate-components";
import moment from "moment";
import { encodeNumber } from "../../utils/strings";

/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";
import * as additionalMediaActions from "../../data/AdditionalMedia/additionalMediaActions";

type CourseDiscussionItemProps={
  idComponent:string | undefined,
  refComponent:any,
  course:any,
  goToReference:string,
  coursesList:[any]
}

const CourseDiscussionItemComponent = ({idComponent, refComponent,course,goToReference,coursesList}:CourseDiscussionItemProps) => {
  
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const itemRedirect = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);

  /* ********* Functions ********* */
  const completeItem = () => {
    const employeeId = userSession.userId;
    if (employeeId > 0 &&  courseId!=null && courseId !="")
      dispatch(landingActions.setCourseCompleted(employeeId, courseId));
    itemRedirect.current.click();
  };

  const prerequisiteText = (course) => {
    if (typeof course == "undefined" || course === null) return "";
    var type = "";
    var icon = "";
    switch (course.fkInstituteCourseType) {
      case 1:
        type = "course_type_video";
        icon = "ondemand_video";
        break;
      case 2:
        type = "course_type_reading";
        icon = "menu_book";
        break;
      case 3:
        type = "course_type_quiz";
        icon = "fact_check";
        break;
      case 4:
        type = "course_type_discution";
        icon = "supervised_user_circle";
        break;
      case 5:
        type = "course_type_donwload_material";
        icon = "download";
        break;
    }
    return (
      <>
        <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
        {"  "}
        <span>{course?.name}</span>
      </>
    );
  };

  const existPrerecordedVideo = () => {
    if (course.additionalMedia !== null) {
      let element = course.additionalMedia.find(
        (element) => element.fkInstituteAdditionalMediaType === 2
      );
      if (element !== undefined) {
        return true;
      }
    }

    return false;
  };

  const saveVideoInStateAndRedirect = () => {
    let element = course.additionalMedia.find(
      (element) => element.fkInstituteAdditionalMediaType === 2
    );
    if (element !== undefined) {
      dispatch(
        additionalMediaActions.saveMediaProperties(
          element.url,
          course.name,
          element.location,
          element.duration
        )
      );
    }

    navigate("/course-video/" +encodeNumber(course.id));
  };
  const  appendRequisites = (previousValue, currentValue, index) =>[
    ...previousValue,
    <li key={index}>
      {prerequisiteText(
        coursesList.find((v) => v.id == currentValue)
      )}
    </li>,
  ]

  const prerequisites = String(course.prerequisites)
    .split(",")
    .reduce(
       appendRequisites,
      [] as Array<string>
    );

  const joinToMeetingButton = () => {
    return (
      <a className="access-course" onClick={() => completeItem()}>
        <span className="material-icons">video_camera_front</span>
        <span>
          {/* <Translate>EnterLive</Translate> */}
          <Translate>joingroupdiscussion</Translate>
        </span>
      </a>
    );
  };

  const upcomingButton = () => {
    return (
      <div className="access-course">
        <span className="material-icons">schedule</span>
        <div>
          <Translate>upcoming</Translate>
        </div>
      </div>
    );
  };

  const preRecordedButton = () => {
    return existPrerecordedVideo() ? (
      <a className="access-course" onClick={saveVideoInStateAndRedirect}>
        <span className="material-icons">ondemand_video</span>
        <span>
          <Translate>Prerecorded</Translate>
        </span>
      </a>
    ) : null;
  };

  const renderReadyToDisplayView = (course, defaultDisplay) => {
    if (
      course.readyToDisplay == null ||
      course.readyToDisplay == true
    )
      return defaultDisplay;
    else
      return (
           course.isLocked ==true ? (
          <div className="access-course">
            <i className="material-icons text-warning">lock</i>
            <span>
              <Translate>Locked</Translate>
            </span>
          </div>
        ) : (
        <>

          {preRecordedButton()}
          {!existPrerecordedVideo() &&upcomingButton()}
</>
        )
      );
  };

  return (
    <div      
      id={idComponent}
      ref={refComponent}
      className={
        "list-item list-item-contrast" +
        " indentation-" +
        course.indentation +
        " " +
        course.style
      }
    >
      <div className="discussion-mark" id="video" />
      {course.isLocked ?
      <div
        className="hidden"
        rel="noreferrer"
      ></div>:
      <a
        className="hidden"
        target="_blank"
        href={goToReference }
        ref={itemRedirect}
        rel="noreferrer"
      ></a>
      }
      <div className="video-icons">
    {course.isLocked ? <div className="video-image"><img src={course.img} className="splash" alt="" /></div>:   <a role="button" onClick={() => completeItem()} className="video-image">
          <img src={course.img} className="splash" alt="" />
        </a>}
      </div>
      <div className="item-description ">
        <h1>{course.name}</h1>
        <div className="course-title">
          <i className="material-icons text-success">supervised_user_circle</i>
          &nbsp;<Translate>title_discussion</Translate>
        </div>
        <div className="course-description">{course.description}</div>
        <div className="video-amount-info">
          <div className="videos-ammount">
            {course.readyToDisplay ? (
              <>
                <span>
                  {moment(course.scheduleDate).format(
                    "DD/MMM/YYYY HH:MM A"
                  )}
                </span>
                <Translate>schedule_date</Translate>{" "}
              </>
            ) : null}
          </div>
        </div>
      </div>

      {course.isLocked ? (
        <>
          <div className="lock-info">
            <div className="lock-title">
              <span className="material-icons">info</span>
              <span>
                <Translate>require_unlock</Translate>
              </span>
            </div>
            <div className="requirements">
              <ul>{prerequisites}</ul>
            </div>
          </div>
        </>
      ) : (
        <>
          {course.isCompleted ? (
            <>
              <div className="course-completion"></div>
              <div className="course-completion">
                <div className="completion">
                  <i className="material-icons text-success">
                    check_circle_outline
                  </i>
                </div>
                <div className="completion-title">
                  <Translate>completed</Translate>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="course-completion"></div>
              <div className="course-completion"></div>
            </>
          )}
        </>
      )}

      {renderReadyToDisplayView(
        course,
        course.isLocked == true ? (
          <div className="access-course">
            <i className="material-icons text-warning">lock</i>
            <span>
              <Translate>Locked</Translate>
            </span>
          </div>
        ) : (
          <>
            {preRecordedButton()}
            {joinToMeetingButton()}
          </>
        )
      )}
    </div>
  );
};

export default CourseDiscussionItemComponent;
