import * as React from 'react';
import {Accordion} from 'react-bootstrap';
import Translate from 'translate-components'
interface CourseDetailsItemProps {
    item:Array<any>,
    index:string,
    changeToggle:(a)=>void

}


const CourseDetailsItem = (props: CourseDetailsItemProps) => {
  //var videos = props.course.courses.filter(c=>c.prerequisites.includes(props.item.id) && c.fkInstituteCourseType==1)
  
  var videos = props.item.filter(c=> c.fkInstituteCourseType==1 )
  var readings =props.item.filter(c=> c.fkInstituteCourseType==2  )
  var quiz = props.item.filter(c=> c.fkInstituteCourseType==3  )
  var groups = props.item.filter(c=> c.fkInstituteCourseType==4  )
  
  return (
    <Accordion.Item eventKey={props.index}  >
       {props.item!=null && props.item.length > 0  &&<Accordion.Header onClick={()=>props.changeToggle(props.index)}> {props.item[0].name}</Accordion.Header>}
        <Accordion.Body className="padding-all-0">
        {videos!= null && videos.length>0 &&
          <div className="padding-all-20 d-flex border-b-papergray">
            <span className="material-icons text-success right-4">
            play_circle
            </span>
            
            <p className="bottom-0">{videos.length} {videos.length>1? <Translate>title_videos</Translate>: <Translate>title_video</Translate>} </p>
        </div>}

       {readings!= null && readings.length>0 &&
        <div className="padding-all-20 d-flex border-b-papergray">
            <span className="material-icons text-success right-4">
            menu_book
            </span>
            <p className="bottom-0">{readings.length}  {readings.length>1?<Translate>title_readings</Translate>:<Translate>title_reading</Translate>}</p>
        </div>
        }
        {
          quiz!= null && quiz.length>0 &&
        <div className="padding-all-20 d-flex border-b-papergray">
            <span className="material-icons text-success right-4">
            quiz
            </span>
            <p className="bottom-0">{quiz.length} {readings.length>1? <Translate>course_type_quizzes</Translate>: <Translate>course_type_quiz</Translate>}</p>
        </div>}
        {
          groups!= null && groups.length>0 &&
        <div className="padding-all-20 d-flex border-b-papergray">
            <span className="material-icons text-success right-4">
            video_camera_front
            </span>
            <p className="bottom-0">{groups.length} {groups.length>1?<Translate>title_discussions</Translate>:<Translate>title_discussion</Translate>}</p>
        </div>}
        </Accordion.Body>
  </Accordion.Item>
  );
};

export default CourseDetailsItem;