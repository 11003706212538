import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "translate-components";
/* ********* Actions ********* */
import "../../styles/styles.scss";
import { materials } from "../../data/Static/MyMaterialsData";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";

/* ********* Components ********* */
import LoadingComponent from "../shared/Loading";
import WhatToLearnNextComponent from "./WhatToLearnNextComponent";
import WishlistComponen from "./WishlistComponen";
import PreviewModalComponent from "./PreviewModal";
import ELTComponentAuth from "../shared/ELTComponentAuth";
import CourseMaterials from "./Materials";
import CourseList from "./CourseList";
import CourseListPagination from "./CoursePagination";

const DashboardComponent = () => {
  const [state, setState] = useState({
    selectedGrade: "tgrViveListo",
    userId: 0,
    pageIndex: 1,
    pageSize: 2,
    filter: "",
    selectedCategory: 0,
    selectedVersion: 0,
    supportedSystem: process.env.REACT_APP_SUPPORTED_SYSTEM,
  });

  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const isLoading = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const allCourses = useSelector(
    (state: RootStateOrAny) => state.dashboard.coursesList
  );

  const wishlistItems = useSelector(
    (state: RootStateOrAny) => state.dashboard.wishlist
  );
  const [filter, setFilter] = useState("");
  const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);

  /* ********* Effects ********* */
  useEffect(() => {
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    getUserCoursesList(userSession.userId, lang);
    dispatch(dashboardActions.getUserWishlist(userSession.userId));
  }, [lang, dispatch]);

  /* ********* Constants ********* */
  const materialsData = materials["supportedSytem" + state.supportedSystem];
  const courses = allCourses.filter(
    (c) =>
      c.name.indexOf(state.filter) !== -1 &&
      (state.selectedCategory === 0 ||
        c.fkInstituteProduct === state.selectedCategory)
  );

  //Filter using completionPercentage
  const coursesFiltered = courses.filter(
    (cf) =>
      filter == "" ||
      (filter == "completed" && cf.completionPercentage == 100) ||
      (filter == "progress" && cf.completionPercentage < 100)
  );

  const getUserCoursesList = (userId, lang) =>
    dispatch(dashboardActions.getLisUserCourses(userId, lang));

  return (
    <ELTComponentAuth>
      {isLoading ? (
        <LoadingComponent isList={true} count={2} />
      ) : (
        <>
          <PreviewModalComponent
            previewItem={selectedPreviewItem}
            onClose={() => setSelectedPreviewItem(null)}
            userId={userSession.userId}
          ></PreviewModalComponent>
          <div className="padding-top-100 bkg-papergray border-b-lightgray">
            <div className="container">
              <div className="row">
                </div>
              <div className="row">
                <div className="col-12">
                  <h1 className="dash">
                    <Translate>Dashboard_My_Courses</Translate>
                  </h1>
                </div>
                <div className="d-flex flex-wrap">
                  <a
                    className={filter == "" ? "dashTabs active" : "dashTabs"}
                    onClick={() => setFilter("")}
                  >
                    <Translate>Dashboard_All_My_Courses</Translate>
                  </a>

                  <a
                    className={
                      filter == "progress" ? "dashTabs active" : "dashTabs"
                    }
                    onClick={() => setFilter("progress")}
                  >
                    <Translate>Dashboard_In_Progress</Translate>
                  </a>

                  <a
                    className={
                      filter == "completed" ? "dashTabs active" : "dashTabs"
                    }
                    onClick={() => setFilter("completed")}
                  >
                    <Translate>Dashboard_Completed</Translate>
                  </a>

                  <a
                    className={
                      filter == "wishlist" ? "dashTabs active" : "dashTabs"
                    }
                    onClick={() => setFilter("wishlist")}
                  >
                    <Translate>Dashboard_Wishlist</Translate>
                  </a>
                  {materialsData != null &&
                    (materialsData.othersMaterials != null ||
                      materialsData.tgrAppLinks) && (
                      <a
                        className={
                          filter == "resources" ? "dashTabs active" : "dashTabs"
                        }
                        onClick={() => setFilter("resources")}
                      >
                        <Translate>Dashboard_Links_Resources</Translate>
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
          {filter == "wishlist" ? (
            <WishlistComponen
              wishList={wishlistItems}
              showPreview={setSelectedPreviewItem}
            ></WishlistComponen>
          ) : filter == "resources" ? (
            <div className="dashcoursesArea">
              <CourseMaterials
                id={"dashCoursesAreaMareials01"}
                data={materialsData}
                selectedGrade={materialsData.grades.find(
                  (g) => g.key === state.selectedGrade
                )}
                version={state.selectedVersion}
                hidelinks={false}
              ></CourseMaterials>
            </div>
          ) : (
            <>
              <CourseList
                userName={userSession.firstName + " " + userSession.lastName}
                courses={coursesFiltered}
              ></CourseList>
              {/* {contPages > 1 ? (
                <CourseListPagination
                  contPages={contPages}
                  selectedPage={state.pageIndex}
                  gotoNextPage={gotoNextPage}
                gotoPrevPage={gotoPrevPage}
                  changeSelectedPage={changeSelectedPage}
                ></CourseListPagination>
              ) : null} */}
            </>
          )}
        </>
      )}

      {/* <WhatToLearnNextComponent
        userId={userSession.userId}
        lang={lang}
        showPreview={setSelectedPreviewItem}
      ></WhatToLearnNextComponent> */}
      <div className="main hidden">
        <div className="header-spacer" />
        <CourseMaterials
          id={"MaterialsCourse03"}
          data={materialsData}
          selectedGrade={materialsData.grades.find(
            (g) => g.key === state.selectedGrade
          )}
          version={state.selectedVersion}
        ></CourseMaterials>
      </div>
    </ELTComponentAuth>
  );
};

export default DashboardComponent;
