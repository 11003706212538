import * as React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

interface Role {
  name: string;
}

const AdminTopButtons = ({isProgressDashboard = false, isNewUser =false, isCourseAssignation = false, isCourseHierarchy=false}) =>  { 
  const instituteRoles = useSelector((state: RootStateOrAny) =>state.instituteRole.instituteRoles);
  const isAdmin = () => {
    if (instituteRoles != null) {
      let role = instituteRoles.find((role) => role.name == "admin");
      if (role != null) {
        return true;
      }
    }
    return false;
  }

  const isLeader = () => {
    if (instituteRoles != null) {
      let role = instituteRoles.find((role) => role.name == "leader");
      if (role != null) {
        return true;
      }
    }
    return false;
  }
  return <>
   <div className="padding-top-100 bkg-papergray border-b-lightgray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="dash">Admin</h1>
              <div className="d-flex flex-wrap">
                {isAdmin() && <a href="/admin/newUser" className={isNewUser ?"dashTabs active":"dashTabs"}>
                  New User
                </a>}
                {isAdmin() && <a href="/admin/organizationHierarchy" className={isCourseHierarchy ?"dashTabs active":"dashTabs"}>
                  Organization&#39;s Hierarchy 
                </a>}

                {(isAdmin() || isLeader()) && <a href="/admin/courseAssignation" className={isCourseAssignation ?"dashTabs active":"dashTabs"}>
                  Course Assignments
                </a>}
                {(isAdmin() || isLeader()) && <a href="/admin/courseProgress" className={isProgressDashboard ?"dashTabs active":"dashTabs"}>
                  Course Progress
                </a>}
              </div>
            </div>
          </div>
        </div>
      </div>
</>};
export default AdminTopButtons;

