import axios from "axios";
//import querystring from 'querystring';

class CourseAssignationServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getById(id, idSupportedSystem, token) {
    const theUrl =
      this.tgrApi + "/Organization/GetOrganizationsByEmployee/" + id + "/" + idSupportedSystem;

    return axios
      .get(`${theUrl}`, {
        baseURL: this.tgrApi,
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}

export default CourseAssignationServices;
