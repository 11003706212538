import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider,  RootStateOrAny, useSelector } from "react-redux";
import { ThemeContext, Theme } from "../themes/themeContext";
import { TranslateProvider } from "translate-components";
import translations from "../translations/global.json";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from "./App";

const AppWithProviders=()=>{
  const selectedLanguageCode =useSelector((state: RootStateOrAny)=> state.application.selectedLanguageCode );
  return (
    <ThemeContext.Provider value={Theme}>
      <TranslateProvider translations={translations} defaultLanguage={selectedLanguageCode != "" && selectedLanguageCode !=null && selectedLanguageCode!= undefined ? selectedLanguageCode:process.env.REACT_APP_DEFAULT_LANGUAGE} >
        <App />
        <ToastContainer />
      </TranslateProvider>
  </ThemeContext.Provider>
)
};

const Root =props=> {
    const { store } = props;
    return (
      <Provider store={store}>
        <AppWithProviders/>
      </Provider>
    );
  }


Root.propTypes = {
  store: PropTypes.object.isRequired
};



export default Root;
