import * as React from 'react';

interface SmartSelectComponentProps {
    onChange:(p)=>void,
    list:any,
    display:string,
    label?: string | undefined
}

const SmartSelectComponent = ({label , onChange, list, display}: SmartSelectComponentProps) => {

    React.useEffect(()=>{
        if(list.length == 1)
            if(onChange)
                onChange({target:{value:list[0].id}});
    },[list]);

  return (
    <>
    {label!= null &&<label>{label}</label>}
      <select className="w-100" onChange={onChange} disabled={list.length<=1}>
          {list?.length == 1 ? <option key={0} value={list[0]}>{list[0].name}</option>:list?.length == 0 ? <option key={0} value={0}>No {display} found</option>:<option key={0} value={0}>{display}</option>}
        {list.map((o,i)=>{
          return <option key={i+'smrtselect'} value={o.id}>{o.name}</option>;
        })}
    </select>
    </>

  );
};

export default SmartSelectComponent;

