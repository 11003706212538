import axios from "axios";
import { Last } from "react-bootstrap/esm/PageItem";
//import querystring from 'querystring';

class CourseAssignationServices {
  constructor() {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    this.token = localStorage.getItem("token");

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getById(id, lang) {
    const theUrl =
      this.tgrApi + "/InstituteProduct/GetProduct/" + id + "/" + lang;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getAllProducts(lang) {
    const theUrl =
      this.tgrApi +
      "/InstituteProduct/GetAllProducts/" +
      this.supportedSystem +
      "/" +
      lang;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getAllNewUserInvitationPending(idAssigner) {

    const theUrl =
      this.tgrApi + "/CourseAssignation/GetAllNewUserInvitationPending/" +idAssigner;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
    
  }

  getOrganizationsByEmployee(idLeader, token) {

    const theUrl =
      this.tgrApi + "/CourseAssignation/GetOrganizationsByEmployee/" +  idLeader;
      return axios
      .get(`${theUrl}`, {
        baseURL: this.tgrApi,
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });

  }

  removePendingInvitation(idInvitation) {

    const theUrl =
      this.tgrApi +
      "/CourseAssignation/RemovePendingInvitation/" +idInvitation

    return this.axiosClient.delete(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });

  }

  upsertAvailableCoursers(idLeader,associate, courses) {

    const theUrl =
      this.tgrApi +
      "/CourseAssignation/UpsertAvailableCoursers/" +this.supportedSystem+"/"+idLeader+"/"+associate

    return this.axiosClient.put(`${theUrl}`, courses).catch((error) => {
      return Promise.reject(error);
    });

  }
  
  getCoursesProgress(idOrganization, idEmployee){

    const theUrl = this.tgrApi + "/CourseAssignation/GetCoursesProgress/" +idOrganization+"/"+idEmployee

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getNewUserInvitationsBySupportedSystemId(supportedSystem){
      
      const theUrl = this.tgrApi + "/CourseAssignation/GetNewUserInvitationsBySupportedSystemId/" +supportedSystem
  
      return this.axiosClient.get(`${theUrl}`).catch((error) => {
        return Promise.reject(error);
      });
  }
  
  getPendingInvitationById(idInvitation){
    const theUrl = this.tgrApi + "/CourseAssignation/GetPendingInvitationById/" +idInvitation

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
  
}

export default CourseAssignationServices;
