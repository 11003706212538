import { configureStore } from "@reduxjs/toolkit";

import userSessionReducer from "../data/UserSession/userSessionReducer";
import applicationReducer from "../data/Application/applicationReducer";
import dashboardReducer from "../data/Dashboard/dashBoardReducer";
import landingReducer from "../data/Landing/landingReducer";
import quizReducer from "../data/Quiz/quizReducer";
import readingReducer from "../data/Reading/readingReducer";
import subscriptionPlanReducer from "../data/SubscriptionPlan/subscriptionPlanReducer";
import additionalMediaReducer from "../data/AdditionalMedia/additionalMediaReducer";
import certificateReducer from "../data/Certificates/certificatesReducer";
import resetTokenReducer from "../data/ResetTokenPassword/resetTokenPasswordReducer";
import emplooyeeLicenseReducer from "../data/EmployeeLicense/employeeLicenseReducer";
import instituteRoleReducer from "../data/InstituteRole/instituteRoleReducer";
const store = configureStore({
  reducer: {
    userSession: userSessionReducer,
    application: applicationReducer,
    dashboard: dashboardReducer,
    landing: landingReducer,
    quiz: quizReducer,
    subscriptionPlan: subscriptionPlanReducer,
    additionalMedia: additionalMediaReducer,
    reading: readingReducer,
    certificate: certificateReducer,
    resetToken: resetTokenReducer,
    employeeLicense: emplooyeeLicenseReducer,
    instituteRole: instituteRoleReducer
  },
});

export default store;
