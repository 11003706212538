import * as types from "../constants/actionTypes";
import InstituteRoleServiceInstance  from "../services/instituteRoleServices"


const institureRoleService = new InstituteRoleServiceInstance()
export function getInstuteRoleActionsByEmployee(userId){
    const token = localStorage.getItem("token");
    return function (dispatch){
        dispatch({type:types.FLIGHT_STARTED})
        institureRoleService.retrieveInstituteRoleByEmployeeId(userId, token).then(r=>{
            dispatch({
                type: types.DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID, payload:r
            });
            dispatch({type:types.FLIGHT_ARRIVED});
        })
    }
}