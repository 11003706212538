import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import * as actions from "../../data/Dashboard/dashBoardActions"
import { toast } from "react-toastify";
import SmartSelectComponent from "./Components/SmartSelectComponent";

const CourseAssignation = () => {

  const dispatch = useDispatch();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const instituteRoles = useSelector((state: RootStateOrAny) =>state.instituteRole.instituteRoles);

  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedLeader, setSelectedLeader]= useState();
  const [selectedAssociate, setSelectedAssociate] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  useEffect(()=>{

    getOrganizationBySupportedSystem();
    getAvailableCourses();
  },[]);
  
  //Data Variables

  //Loading Data Functions
  const getOrganizationBySupportedSystem= ()=>{
    dispatch(actions.getOrganizationByAssigner(userSession.userId, process.env.REACT_APP_SUPPORTED_SYSTEM));
  }

  //Selectors
  const organizations = useSelector( (state: RootStateOrAny) => state.dashboard.organizations);
  const leaders = useSelector( (state: RootStateOrAny) => state.dashboard.leaders);
  const associates = useSelector( (state: RootStateOrAny) => state.dashboard.associates);
  const availableCourses= useSelector( (state: RootStateOrAny) => state.dashboard.availableModules);
  const assignedCourses=useSelector( (state: RootStateOrAny) => state.dashboard.assignedModules);

  const [leadersDisplay, setLeadersDisplay] = useState<any>([]);
  const [asssociatesDisplay, setAssociatesDisplay] = useState<any>([]);
  const [displayAvailableCourses, setDisplayAvailableCourses] = useState<any>([]);

  const reset=()=>{
    setLeadersDisplay([]);
    setAssociatesDisplay([]);
    setAssignedCoursesNewList([]);
    setDisplayAvailableCourses([]);
    setSelectedAssociate(undefined);
    setSelectedLeader(undefined);
  }
  
  //Effects
  useEffect(()=>{    
    if(selectedOrganization != null && selectedOrganization != 0 ){
      dispatch(actions.getLeadersByOrganization(selectedOrganization));
      getAvailableCourses();
    }
  },[selectedOrganization])

  useEffect(()=>{
    if(selectedLeader != null && selectedLeader > 0 ){
      dispatch(actions.getAssociatesByLeader(selectedLeader,selectedOrganization));
    }
  },[selectedLeader]);

  useEffect(()=>{
    getAssignedCourses();
  },[selectedLeader,selectedAssociate]);

  useEffect(()=>{
    setAssignedCoursesNewList([...assignedCourses]);
  },[assignedCourses])

  useEffect(()=>{
     if(selectedOrganization!=null)
      var isOgAdmin = instituteRoles.find((role) => role.name == "admin" && role.organizationId == selectedOrganization);
      if(isOgAdmin)
        setLeadersDisplay([...leaders]);
      else{
        setSelectedLeader(userSession.userId);
        setLeadersDisplay(leaders.filter((l)=>l.id == userSession.userId));
      }

  },[leaders]);

  useEffect(()=>{
    setAssociatesDisplay([...associates]);
  }
  ,[associates]);

  useEffect(()=>{
    setDisplayAvailableCourses([...availableCourses]);
  },[availableCourses]);

  const getAvailableCourses =()=>{
    if(selectedOrganization != null  ){
      dispatch(actions.getAvaibleInstituteModuleByOrganization(selectedOrganization));
    }
  }

  const getAssignedCourses =()=>{
    if(selectedLeader != null && selectedLeader > 0  && selectedAssociate != null && selectedAssociate > 0){
      dispatch(actions.getAssignedInstituteModuleByLeader(selectedLeader,selectedAssociate));
    }
  }

  //Change Functions
  const onchangeOrganization = o => {
    setSelectedOrganization(o.target.value)
    reset();
  }

  const onchangeLeader= l => {
    setSelectedLeader(l.target.value)
  }

  const onchangeAssociate= a=>{
    setSelectedAssociate(a.target.value);
  }

  const onchangeCourse= c=>{
    setSelectedCourse(c.target.value);
  }

  const saveChanges=()=>{
    const newCourses =assignedCoursesNewList.map((ac)=>ac.id);
    dispatch(actions.upsertAvailableCourses(process.env.REACT_APP_SUPPORTED_SYSTEM, selectedLeader,selectedAssociate,newCourses,(r)=>{
      if(r){
        toast.success("Courses assigned successfully");
      }else{
        toast.error("Error assigning courses");
      }
        
    }));
  }
  const [assignedCoursesNewList, setAssignedCoursesNewList] = useState<any>([]);

  const addCourse =()=>{
    const module = availableCourses.find((ac)=>ac.id == selectedCourse);
    if(module)
    {
      const moduleExists = assignedCoursesNewList.find((ac)=>ac.id == module.id);
      if(!moduleExists)
        setAssignedCoursesNewList([...assignedCoursesNewList, module])
    }
   ;
  }

  const removeCourse =(id)=>{
    const newCourses = assignedCoursesNewList.filter((ac)=>ac.id != id);
    setAssignedCoursesNewList(newCourses);
  }

  return (
    <>
    <div className="container py-4  min-vh-100">
      <div className="row">
        <div className="col-12">
          <h1 className="dash my-2">Course Assignment</h1>
          <hr />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <SmartSelectComponent label={"Organization"} list={organizations} display="Select an organization" onChange={onchangeOrganization}/>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <SmartSelectComponent list={leadersDisplay.map(i => ({
                  ...i,
                  name: `${i.firstName} ${i.lastName}, ${i.email}`
              }))}
              label="Leader"
              display="Select a leader" onChange={onchangeLeader}
              />
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <SmartSelectComponent list={asssociatesDisplay.map(i => ({
                  ...i,
                  name: `${i.firstName} ${i.lastName}, ${i.email}`
              }))}
              label="Associate"
              display="Select Associate" onChange={onchangeAssociate}
              />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex align-items-end">
            <div className="d-flex flex-column w-100 right-10">
              <SmartSelectComponent list={displayAvailableCourses}
              label="Course"
              display="Select a Course" onChange={onchangeCourse}
              />
            </div>
            <button className="btn btn-flat-secondary" onClick={addCourse}>
              Add Course
            </button>
          </div>
        </div>
      </div>
      
      <div className="row mt-4">
        <div className="col-12">
          <h1 className="dash my-2 smaller">Assigned Courses</h1>
          <hr />
        </div>
      </div>
      
      {/* users  */}
      <div className="row align-items-baseline ">

        {/* User sample */}
        {assignedCoursesNewList.map((ac,i)=> <div className="col-12 col-md-6 p-2">
          <div className="d-flex bkg-papergray p-2 rounded-2 justify-content-between align-items-center">
            <div >
              {ac.name}
            </div>
            <button className=" btn btn-sm btn-flat-papergray " onClick={()=>removeCourse(ac.id)}>
              <i className="material-icons text-warning">close</i>
            </button>
          </div>
        </div>
        )}
        <div className="col-12">
          <hr />
        </div>

      </div>
      
      <div className="row mt-2" onClick={saveChanges}>
        <div className="col-12 d-flex">          
          <button className="btn btn-flat-secondary d-flex">
            <span className="right-6">
              Save Changes
            </span>
            <i className="material-icons text-background">save</i>
          </button>
        </div>
      </div>

    </div>
    </>
  );
};

export default CourseAssignation;
