import ELTComponentAuth from "../shared/ELTComponentAuth";
import NewUser from "./NewUser";
import OrganizationHierarchy from "./OrganizationHierarchy";
import CourseAssignation from "./CourseAssignation";
import ProgressDashboard from "./ProgressDashboard";
import AdminTopButtons from "./AdminTopButtons";
import { useSelector, RootStateOrAny } from "react-redux";
import { useEffect } from "react";


const AdminScreen = ({isProgressDashboard = false, isNewUser =false, isCourseAssignation = false, isCourseHierarchy=false}) => {
  const instituteRoles = useSelector((state: RootStateOrAny) =>state.instituteRole.instituteRoles);


  const isAdmin = () => {
    if (instituteRoles != null) {
      let role = instituteRoles.find((role) => role.name === "admin" || role.name === "leader");
      if (role != null) {
        return true;
      }
    }
    return false;
  }

  //Effects
  useEffect(() => {

    if(!isAdmin()){
      window.location.href = "/";
  }
  },[])
  
  return (
    <>
    <ELTComponentAuth>
   
      <AdminTopButtons isCourseAssignation={isCourseAssignation} isNewUser={isNewUser} isProgressDashboard={isProgressDashboard} isCourseHierarchy={isCourseHierarchy}  ></AdminTopButtons>
      {isNewUser && <NewUser />}
      {isCourseHierarchy && <OrganizationHierarchy />}

      {isCourseAssignation && <CourseAssignation />}

      {isProgressDashboard && <ProgressDashboard />}
      
    </ELTComponentAuth>
    </>
  );
};

export default AdminScreen;
