import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Translate from "translate-components";
import { decodeNumber } from "../../utils/strings";
import "../../styles/styles.scss";

/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";

/* ********* Components ********* */
import AudioPlayer from "./AudioPlayer";
import ELTComponentAuth from "../shared/ELTComponentAuth";
// @ts-ignore
import imgAudio from "../../assets/images/audio-player.jpg";

const CourseAudioPlayer = () => {
  /* ********* Uses ********* */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseId } = useParams();

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const audioName = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaName
  );
  const audioUrl = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaUrl
  );
  const audioLocation = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaLocation
  );
  const audioDuration = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaDuration
  );

  /* ********* Functions ********* */
  const completeCourse = () => {
    const employeeId = userSession.userId;
    if (employeeId > 0 && courseId != null && courseId != "")
      dispatch(
        landingActions.setCourseCompleted(employeeId, decodeNumber(courseId))
      );
  };

  return (
    <ELTComponentAuth>
      <div id="VideoItem">
        <div className="header-spacer" />
        <>
          <div className="videoTitle">
            <h1>{audioName}</h1>
          </div>
          <div>
            <AudioPlayer
              audioSource={audioUrl}
              audioImage={imgAudio}
              audioTitle={audioName}
              audioArtist=""
              audioDuration={audioDuration}
              completeCourse={completeCourse}
              mustCompleteCourse={true}
            />
          </div>

          <div className="video-footer w-100"></div>
          <div className="bottom-section d-flex justify-content-end">
            <div className="">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-success"
              >
                <span>
                  <Translate>gotocourse</Translate>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </ELTComponentAuth>
  );
};

export default CourseAudioPlayer;
