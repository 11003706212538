import React, { useEffect, useState , } from "react";

import * as actions from "../../data/Dashboard/dashBoardActions"
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import SmartSelectComponent from "./Components/SmartSelectComponent";
const ProgressDashboard = () => {

  const organizations = useSelector( (state: RootStateOrAny) => state.dashboard.organizations);
  const leaders = useSelector( (state: RootStateOrAny) => state.dashboard.leaders);
  const assignedCoursesProgress =useSelector((state: RootStateOrAny) => state.dashboard.assignedCoursesProgress);

  const instituteRoles = useSelector((state: RootStateOrAny) =>state.instituteRole.instituteRoles);
  const [selectedOrganization, setSelectedOrganization] = useState(0);
  const [selectedLeader, setSelectedLeader]= useState(0);
  const [filter, setFilter]=  useState("");
  const [filteredList, setFilteredList] = useState<any>([]);
  const [displayLeaders, setDisplayLeaders] = useState<any>([]);

  const [preFilter, setPreFilter] = useState("")
  const dispatch = useDispatch();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);

  const getOrganizations =()=>{
    if(userSession &&
      userSession.isLoggedIn())
      dispatch(actions.getOrganizationByAssigner(userSession?.userId, process.env.REACT_APP_SUPPORTED_SYSTEM))
  }

  const getCoursesProgress = ()=>{
    dispatch(actions.getCoursesProgress(selectedOrganization, selectedLeader))
  }

  //Effects Methods

  useEffect(()=>{

    getOrganizations();

  },[]);

  useEffect(()=>{
      setFilteredList([]);
      setDisplayLeaders([]);
    if(selectedOrganization !=0)
    {
      dispatch(actions.getLeadersByOrganization(selectedOrganization));
      
    }

  },[selectedOrganization])

  useEffect(()=>{
    if(selectedOrganization!=null)
     var isOgAdmin = instituteRoles.find((role) => role.name == "admin" && role.organizationId == selectedOrganization);
     if(isOgAdmin)
       setDisplayLeaders([...leaders]);
     else{
       setSelectedLeader(userSession.userId);
       setDisplayLeaders(leaders.filter((l)=>l.id == userSession.userId));
     }

 },[leaders]);

  useEffect(()=>{
    LoadCoursesProgress()

  },[selectedOrganization, selectedLeader])

  useEffect(()=>{
    if(filter != "")
     setFilteredList(assignedCoursesProgress.filter(ac=> ac.name.toLowerCase().includes(filter.toLowerCase())));
    else
      setFilteredList(assignedCoursesProgress);
  },[filter]);

  useEffect(()=>{
    setFilteredList(assignedCoursesProgress);
  },[assignedCoursesProgress])

  // Events 
  const onchangeOrganization = o => {
    setSelectedOrganization(o.target.value);
    LoadCoursesProgress();
  }

  const onchangeLeader= l => {
    setSelectedLeader(l.target.value)
  }

 const onSearch= ()=>{
  setFilter(preFilter);
 }

 const LoadCoursesProgress=()=>{
  if(selectedLeader >0 && selectedOrganization != 0){
    getCoursesProgress();
  }
 }

 function isValidDate(dateString) {
  // Try to parse the date string
  const date = new Date(dateString);

  // Check if the date is valid
  return !isNaN(date.getTime());
}

 const getDate=(d)=>{
  if(isValidDate(d))
    return new Date(d).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  else
    return "n/a";
 }
  return (
    <>
    <div className="container py-4 min-vh-100">
      <div className="row">
        <div className="col-12">
          <h1 className="dash my-2">Course Progress Dashboard</h1>
        </div>
      </div>

      <div className="row border-b-lightgray mb-4"> </div>
      
      <div className="row">
        <div className="col-12 col-md-5">
          <SmartSelectComponent list={organizations} label="Organization" display="Select an organization" onChange={onchangeOrganization}/>
        </div>
        <div className="col-12 col-md-5">
          <SmartSelectComponent list={displayLeaders.map(i => ({
              ...i,
              name: `${i.firstName} ${i.lastName}, ${i.email}`
          }))} label="Leader" display="Select a leader" onChange={onchangeLeader}/>
        </div>
        <div className="col-12 col-md-2 hidden">
          <button className="w-100 btn  btn-flat-secondary d-flex justify-content-center" onClick={LoadCoursesProgress}>
            Filter
            <i className="material-icons text-background left-5">tune</i>
          </button>
        </div>
      </div>

      <div className="row border-b-lightgray my-4"> </div>

      <div className="row my-4">
        <div className="col-12 col-md-6">
          <h1 className="dash smaller">
          Total Users: {assignedCoursesProgress.length}
          </h1>
        </div>
        <div className="d-flex justify-content-end col-12 col-md-6">
          <div className="position-relative ">
            <input  type="search" className="rounded-5 padding-left-30" value={preFilter} onChange={v=>setPreFilter(v.target.value)}  onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}/>
            <div className="position-absolute height-100p top-0 d-flex justify-content-center align-items-center padding-left-5">
              <i className=" material-icons"> search</i>
            </div>
          </div>
          <button className="btn btn-sm btn-flat-secondary d-flex align-items-center left-5 rounded-5 px-3" onClick={onSearch} >
            Search <i className=" material-icons">search</i>
          </button>
        </div>
      </div>

      {/* <div className="row border-b-lightgray my-4"> </div> */}
      
    
      {filteredList.map(cp=>{return <> 
      <div className="row border-b-lightgray align-items-lg-baseline border-t-papergray bkg-accent2"> 
        <div className="col-12 col-md-6 fw-medium text-accent4">
          {cp.coursesProgress?.length > 0 && <button className="btn btn-sm btn-flat-accent2 ">
            {/* <i className="material-icons text-main right-5">keyboard_arrow_right</i> */}
            <i className="material-icons text-main right-5">keyboard_arrow_down</i>
          </button>}
          {cp.name}
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-md-end text-primary">
          Enrolled Courses: {cp.coursesProgress?.length}
        </div>
        {/* <div className="col-6 col-md-3 text-right">
          Last Update: Sept 30, 2024
        </div> */}
      </div>

      {
        cp.coursesProgress?.length>0 && <div className="mb-4 shadow-2 border-t-papergray">
        <div className="row bkg-accent">
          <div className="col-5 padding-left-50">
            Course Name
          </div>
          <div className="col-4 text-center">
            Progress
          </div>
          <div className="col-1 text-center">
            %
          </div>
          <div className="col-2 text-right">
            Last Update
          </div>
        </div>

       {cp.coursesProgress?.map(m=> <div className="row bkg-accent2 border-b-lightgray border-t-accent3 pt-2">
          <div className="col-5 padding-left-50 fw-medium">
            {m.courseName}
          </div>
          <div className="col-4 text-center">
            <div className="d-flex flex-column">
              <div>
                {m.countCompleted} out of {m.total}
              </div>
              <div className="d-flex height-4 bkg-accent3 bottom-4 rounded-2 overflow-hidden">
                <div className={"height-4 width-"+ m.progress +"p bkg-success"}></div>
              </div>
            </div>
          </div>
          <div className="col-1 text-center">
          {m.progress}%
          </div>
          <div className="col-2 text-right">
            {getDate(m.lastUpdate)}
          </div>
        </div>)}
        </div>
      }
      </>
    
    })}
    </div>
    </>
  );
};

export default ProgressDashboard;
