import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import ELTComponentAuth from "../shared/ELTComponentAuth";
import * as actions from "../../data/Dashboard/dashBoardActions";
import { Code } from 'react-content-loader'
import { useParams, useNavigate, useLocation } from "react-router-dom";

const InvitationAcceptance = () => {

  const isLoading = useSelector((state: RootStateOrAny) => state.dashboard.isLoading);
  const pendingInvitation = useSelector((state: RootStateOrAny) => state.dashboard.pendingInvitation);
 
  const dispatch = useDispatch();

  const { invitationId } = useParams();
  const [creatingUser, setCreatingUser] = React.useState(false);
  const [successCreatedUser, setSuccessCreatedUser] = React.useState(false);

  useEffect(() => {
    //Todo load the user invitation service
    dispatch(actions.getPendingInvitationById(invitationId));
  }, []);

  useEffect(() => {

    if(isLoading== false && pendingInvitation != null){
      if(pendingInvitation.invitationAccepted == null ){
        setCreatingUser(true);
        dispatch(actions.acceptInvitation( process.env.REACT_APP_SUPPORTED_SYSTEM, invitationId, (r)=>{
          setCreatingUser(false);
          setSuccessCreatedUser(r);
        }));
      }
    }
  }, [isLoading, pendingInvitation]);


  const getOrganizationsNames = () => {
    if (pendingInvitation != null && pendingInvitation.organizations != null) {
      return JSON.parse(pendingInvitation.organizations).map((org: any) => org.name).join(", ");
    }
    return "";
  }

  return (
    <>
      { isLoading == false &&    
        <div className="padding-top-100 bkg-papergray min-vh-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <div className="card rounded-2 top-30 shadow-lg">
                  {/* Creating user loading */}
              {creatingUser && pendingInvitation!= null  && pendingInvitation.invitationAccepted == null &&   <div className="p-4 ">
                    <h1 className="dash text-center fs-4">
                      Your account is being created
                    </h1>
                    <p className="text-center">
                      please wait
                    </p>
                    <Code  />
                    
                  </div>
                }

                  {/* Success Message */}
              {successCreatedUser &&    <div className="p-4 ">
                    <h1 className="text-center dash">Congratulations</h1>
                    <hr /> 
                    <p>
                      You have been successfully added to&nbsp;
                      {/* TODO: change name of institution  */}
                      <span className="fw-bold">
                        {getOrganizationsNames()}
                      </span>
                    </p>

                    <p>
                      Your account has been set up, and you can now access all courses assigned to you. Please check your email for your login credentials and instructions on how to access the system.
                    </p>
                    <p>
                      Check your inbox for an email containing your login details
                    </p>
                    <hr />
                    <div className="d-flex justify-content-center pb-4">
                      <a href="/login"  className="btn btn-secondary d-flex">
                        Click Here to Login
                        <i className="material-icons ">lock</i>
                      </a>
                    </div>
                    <p className="bkg-papergray p-2 text-center text-main rounded-1">
                      If you encounter any issues, feel free to reach out to our support team at&nbsp;
                      <a href="mailto:support@eleadertech.com" className="text-accent4">support@eleadertech.com</a>
                    </p>
                  </div>}

                  {/* Denial Message */}
                  {(pendingInvitation== null || (pendingInvitation && pendingInvitation.invitationAccepted) != null) && 
                  <div className="p-4">
                    <h1 className="text-center dash text-warning fs-3">Access Denied</h1>
                    <hr /> 

                    <p>
                      We&#39;re sorry, but it appears that you do not have the necessary permissions to access the&nbsp; 
                      {/* TODO:change name of institute */}
                      <span className="fw-bold">
                        {getOrganizationsNames()}
                      </span>&nbsp;
                      at this time.
                    </p>
                    <p>
                      Please check the following:
                    </p>
                    <ol>
                      <li>
                        Ensure you have the correct invitation link.
                      </li>
                      <li>
                        Make sure your registration has been completed successfully by your administrator.
                      </li>
                    </ol>

                    <p className="bkg-papergray p-2 text-center text-main rounded-1">
                    If you believe this is an error or if you need assistance, please contact our support team at&nbsp;
                      <a href="mailto:support@eleadertech.com" className="text-accent4">support@eleadertech.com</a>
                    </p>

                  </div>}

                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {isLoading && 
      <div className="padding-top-100 bkg-papergray min-vh-100"><p>Loading...</p></div>
      }
    </>
  );
};

export default InvitationAcceptance;
