import React, { useEffect, useState , } from "react";

import * as actions from "../../data/Dashboard/dashBoardActions"
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import SmartSelectComponent from "./Components/SmartSelectComponent";

const OrganizationHierarchy = () => {
  
  const organizations = useSelector( (state: RootStateOrAny) => state.dashboard.organizations);
  const leaders = useSelector( (state: RootStateOrAny) => state.dashboard.associatesByOrganization);
  const associates = useSelector( (state: RootStateOrAny) => state.dashboard.associatesByOrganization);

  const associatesByLeader = useSelector( (state: RootStateOrAny) => state.dashboard.associates);

  const dispatch = useDispatch();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);

  const [selectedOrganization, setSelectedOrganization] = useState(0);
  const [selectedLeader, setSelectedLeader]= useState(0);
  const [selectedAssociate, setSelectedAssociate] = useState(0);

  const [associatesList, setAssociatesList] = useState<any>([]);
  const [leadersDisplay, setLeadersDisplay] = useState<any>([]);
  const [associatesDisplay, setAssociatesDisplay] = useState<any>([]);


    const getOrganizations =()=>{
      if(userSession &&
        userSession.isLoggedIn())
        dispatch(actions.getOrganizationByAssigner(userSession?.userId, process.env.REACT_APP_SUPPORTED_SYSTEM,))
    }


    const reset=()=>{
      setSelectedLeader(0);
      setSelectedAssociate(0);
      setAssociatesList([]);
      setAssociatesDisplay([]);
      setLeadersDisplay([]);
      setAssociatesDisplay([]);
    }
    //Effects Methods
    useEffect(()=>{
      getOrganizations();
    },[]);
  
    useEffect(()=>{
      if(selectedOrganization !=0)
      {
        //dispatch(actions.getLeadersByOrganization(selectedOrganization));
        dispatch(actions.getAssociatesByOrganization(selectedOrganization));
      }
  
    },[selectedOrganization])

    useEffect(()=>{
      if(selectedLeader !=0)
      {
        dispatch(actions.getAssociatesByLeader(selectedLeader,selectedOrganization));
      }

    },[selectedLeader])

    useEffect(()=>{

      if(associatesByLeader){
        setAssociatesList(associatesByLeader);
      }
      
    },[associatesByLeader])

    useEffect(()=>{

      setLeadersDisplay([...leaders]);

    },[leaders]);

    useEffect(()=>{
      setAssociatesDisplay([...associates.filter(a=> a.id!=selectedLeader)]);
    },[associates, selectedLeader]);
    
    
      // Events 
      const onchangeOrganization = o => {
        reset();
        setSelectedOrganization(o.target.value)
      }

      const onchangeLeader= l => {
        setSelectedLeader(l.target.value)
      }

      const onchangeAssociate= a => {
        setSelectedAssociate(a.target.value)
      }

      const addAssociate =()=>{
        if(selectedAssociate != 0){

          const isAlreadyAdded = associatesList.find(a=>a.id == selectedAssociate);
          if(isAlreadyAdded){
            toast("Associate already added",{type:"error"});
            return;
          }
          
          const associate = associates.find(a=>a.id == selectedAssociate);
          if(associate){
            setAssociatesList([...associatesList, associate]);
            setSelectedAssociate(0);
          }

        }
      }

      const saveChanges =()=>{

        const mapAssociates = associatesList.map(a=>a.id);
        dispatch(actions.saveOrganizationHierarchy(selectedOrganization,selectedLeader,mapAssociates, (r)=>{
          if(r)
            toast("Changes saved successfully");
          else
            toast("There was an error saving the changes",{type:"error"});
        }));

      }
    
      const deleteAssociate =(a)=>{
        const newAssociates = associatesList.filter(al=>al.id != a.id);
        setAssociatesList(newAssociates);
      }

  return (
    <>
    <div className="container py-4  min-vh-100">
      <div className="row">
        <div className="col-12">
          <h1 className="dash my-2">Organization&#39;s Hierarchy</h1>
          <hr />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <SmartSelectComponent list={organizations} label="Organization" display="Select an organization" onChange={onchangeOrganization}/>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <SmartSelectComponent list={leadersDisplay.map(i => ({
                ...i,
                name: `${i.firstName} ${i.lastName}, ${i.email}`
            }))} label="Leader" display="Select a leader" onChange={onchangeLeader}/>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 d-flex align-items-end">
          <div className="d-flex flex-column width-100p">
            <SmartSelectComponent list={associatesDisplay.map(i => ({
                ...i,
                name: `${i.firstName} ${i.lastName}, ${i.email}`
            }))} label="Associate" display="Select an associate" onChange={onchangeAssociate}/>
          </div>
          <button className="btn btn-flat-secondary left-10" onClick={addAssociate}>
            Add
          </button>
        </div>
      </div>
      
      {/* users  */}
      <div className="row border-b-lightgray align-items-baseline  mt-4">
            
        {/* User sample */}
       {associatesList.map(a=> <div className="col-12 col-md-6 p-2">
          <div className="d-flex bkg-papergray p-2 rounded-2 justify-content-between align-items-center">
            <div >
              <span className=" fw-medium">
                {a?.firstName +" " + a?.lastName}
              </span>
              &nbsp; | &nbsp;
              {a?.email}
            </div>
            <button className=" btn btn-sm btn-flat-papergray" onClick={()=>deleteAssociate(a)}>
              <i className="material-icons text-warning">close</i>
            </button>
          </div>
        </div>)}
        
      </div>
      <div className="row mt-2">
        <div className="col-12 d-flex">
          <button className="btn btn-flat-secondary d-flex" onClick={saveChanges}>
            <span className="right-6">
              Save Changes
            </span>
            <i className="material-icons text-background">save</i>
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default OrganizationHierarchy;
