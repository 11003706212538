import * as types from "../constants/actionTypes"
import { simpleDecryption, simpleEncryption } from "../../utils/strings";

const descriptRoles = () => {
    if (typeof window !== "undefined") {
      if (
        window.localStorage.getItem("InstituteRoles") === null ||
        window.localStorage.getItem("InstituteRoles") === undefined
      ) {

        return [];

      } else {
        
        const data = simpleDecryption(window.localStorage.getItem("InstituteRoles"));
        
        if(!data)
          return [];

        return JSON.parse(data);
      }
    }
  }

const initialState={
    instituteRoles:descriptRoles()
}

export default function instituteRoleReducer(state= initialState, action){
    switch(action.type){
        case types.DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID:{
            const {data} = action.payload;
            localStorage.setItem(
                "InstituteRoles",
                simpleEncryption(JSON.stringify(data)) as string
              );
            return {
                ...state,
                instituteRoles:data
            }
        }
        case types.SIGNOUT: {
            localStorage.clear();
            return { ...initialState };
          }
        default:
            return state;
    }
}